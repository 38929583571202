<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Pagination from '@/components/Pagination/main.vue';
import Swal from 'sweetalert2';
import Multiselect from '@/components/Multiselect/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

import { keyMaster, masterMethods, faqMethods, listPaging, checkPermission } from '@/state/helpers';
import { truncateText } from '@/utils/format';
import { showMessage } from '@/utils/messages';
import { InputRadio } from '@/components/Input';

export default {
    page: {
        title: 'FAQ管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Pagination,
        Layout,
        KeyWordTag,
        Multiselect,
        LoadingIcon,
        Footer,
        InputRadio
    },
    data() {
        return {
            listData: [],
            listDataAnswer: [],
            listDataCPTypes: [],
            listSheetFAQ: [],
            listApplicationType: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                question: '',
                keywords: ''
            },
            dataAnswerObj: {},
            perPageList: listPaging,
            has: {
                category_id: ''
            },
            filter: {
                sheet: '',
                campaign_type: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: '質問　回答',
                isShowLable: true
            },
            dataAnswer: {
                answer: '',
                faq_id: 3
            },
            checkShowFilter: false,
            isLoading: false
        };
    },
    watch: {
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        }
    },
    mounted() {
        this.getList();
        this.getListMasterTypes();
        this.getListMasterSheets();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        ...masterMethods,
        ...faqMethods,
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        truncateTextTable(text) {
            return truncateText(text);
        },

        getListMasterSheets() {
            this.listMaster(keyMaster['faq_master.sheets']).then((data) => {
                this.listSheetFAQ = data;
            });
        },
        getListMasterTypes() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataCPTypes = data;
            });
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
        },
        getDetail(id) {
            this.loading = true;
            this.detailFaq(id).then((data) => {
                this.listDataAnswer = data.answers;
                this.dataAnswerObj = data;
                this.loading = false;
                this.dataAnswer.faq_id = data.id;
            });
        },

        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${value}`);
                    }
                }
                if (this.filter) {
                    for (const [key, value] of Object.entries(this.filter)) {
                        if (key && value) {
                            if (key === 'sheet') {
                                query.push(`filters[${key}]=${value.id}`);
                            } else {
                                query.push(`filters[${key}]=${value}`);
                            }
                        }
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) {
                            if (key === 'category_id') {
                                if (value.length) {
                                    query.push(`has[${key}]=${value.map((item) => item.id.toString())}`);
                                }
                            } else {
                                query.push(`has[${key}]=${value}`);
                            }
                        }
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listFaqs(query).then((data) => {
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        onChangeCPType() {
            this.listApplicationType = this.listDataCPTypes.find((item) => item.id === this.filter.campaign_type)?.data;
            this.has.category_id = [];
        },
        search() {
            this.getList();
        },
        clear() {
            this.form = {
                keywords: '',
                question: ''
            };
            this.filter = {
                sheet: '',
                campaign_type: ''
            };
            this.has = {
                category_id: []
            };
            this.configKeyword.arrayTag = [];
            this.getList();
        },

        deleteObjectFaq(data) {
            Swal.fire({
                title: 'このFAQ を削除してもよろしいですか。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteFaq(data).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, 'FAQが削除されました。');
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    });
                }
            });
        },

        register() {
            if (this.dataAnswer.answer && this.dataAnswer.faq_id) {
                this.registerAnswer(this.dataAnswer).then((data) => {
                    if (data.code == 200) {
                        this.dataAnswer.answer = '';
                        this.getDetail(this.dataAnswer.faq_id);
                        showMessage(data.code, this.$bvToast, 'Success');
                    }
                });
            }
        },
        edit(item) {
            if (item.answer) {
                let data = Object.assign(item);
                this.updateAnswer(data).then(() => {
                    showMessage(data.code, this.$bvToast, 'Success');
                });
            }
        },
        setDefault(item) {
            if (item.answer) {
                let data = {
                    answer_default: item.id,
                    id: this.dataAnswer.faq_id
                };
                this.updateFaqsAnswerDefault(data).then(() => {
                    this.getDetail(this.dataAnswer.faq_id);
                    showMessage(data.code, this.$bvToast, 'Success');
                });
            }
        },
        renderTextCPType(params) {
            return this.listDataCPTypes.find((item) => item.id === params)?.value;
        },
        renderTextApplicationType(params) {
            if (params) return params.map((item) => item.category.value).join(', ');
            return '';
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">FAQ検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :keyword="form.keywords" :config="configKeyword" class="flex-fill" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('faq.searchSheet') }}</label>
                                            <div class="w-100">
                                                <Multiselect
                                                    :id="`select-filter-sheet`"
                                                    :value.sync="filter.sheet"
                                                    :options="listSheetFAQ"
                                                    :config="{
                                                        error: false,
                                                        isRemove: false,
                                                        trackBy: 'value',
                                                        label: 'value',
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group d-flex">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('faq.campaignType') }} </label>
                                            <div class="w-100">
                                                <div class="d-flex">
                                                    <div v-for="cpt in listDataCPTypes" :key="cpt.id + 's'">
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="filter.campaign_type"
                                                            :id="`radio${cpt.id}`"
                                                            :value="cpt.id"
                                                            :label="cpt.value"
                                                            @change="onChangeCPType"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('faq.applicationType') }}</label>
                                            <div class="w-100">
                                                <Multiselect
                                                    :id="`select-has-category_id`"
                                                    :value.sync="has.category_id"
                                                    :options="listApplicationType"
                                                    :config="{
                                                        error: false,
                                                        isRemove: false,
                                                        trackBy: 'value',
                                                        label: 'value',
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('faq.question') }}</label>
                                            <div class="w-100">
                                                <textarea id="validationCustom05" v-model="form.question" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-danger btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">FAQ検索結果</h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>
                                            {{ $t('faq.question') }}
                                        </th>
                                        <th>{{ $t('faq.campaignType') }}</th>
                                        <th>{{ $t('faq.applicationType') }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template>
                                        <tr v-for="(item, idx) in listData" :key="item.id">
                                            <td>
                                                {{ parseInt((page - 1) * limit) + parseInt(idx + 1) }}
                                            </td>
                                            <td style="width: 900px; min-width: 500px">
                                                <span
                                                    v-b-tooltip="{
                                                        customClass: 'custom-class-question-tooltip'
                                                    }"
                                                    :title="item.question"
                                                >
                                                    {{ truncateTextTable(item.question) }}</span
                                                >
                                            </td>
                                            <td style="min-width: 150px">
                                                {{ item.campaign_type ? item.campaign_type.value : '' }}
                                            </td>
                                            <td style="min-width: 150px">
                                                {{ renderTextApplicationType(item.categories) }}
                                            </td>

                                            <td align="center" style="min-width: 100px">
                                                <a href="javascript:void(0)" class="text-info border-right-1" @click="goToEdit('/faq/view', item)">{{
                                                    $t('btn.detail')
                                                }}</a>
                                                <a
                                                    v-if="checkPermiss('faq.edit')"
                                                    href="javascript:void(0)"
                                                    class="text-success"
                                                    @click="goToEdit('/faq/form', item)"
                                                    >{{ $t('btn.edit') }}</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>

                        <b-modal id="modal-lg" size="lg" title="回答 新規登録・編集" title-class="font-24" hide-footer>
                            <template>
                                <div v-for="(item, k) in listDataAnswer" class="d-flex align-items-center justify-content-center mb-3" :key="item.id">
                                    <span style="white-space: nowrap" class="mr-3">回答{{ k + 1 }}</span>
                                    <input v-model="item.answer" type="text" class="form-control" />
                                    <button type="button" @click="edit(item)" class="btn btn-info ml-2" style="white-space: nowrap">登録</button>

                                    <button
                                        type="button"
                                        v-if="dataAnswerObj.answer_default == item.id"
                                        @click="setDefault(item)"
                                        class="btn btn-success"
                                        style="white-space: nowrap"
                                    >
                                        Default
                                    </button>
                                    <button
                                        type="button"
                                        v-else
                                        @click="setDefault(item)"
                                        class="btn btn-outline-success"
                                        style="white-space: nowrap"
                                    >
                                        Default
                                    </button>
                                </div>
                            </template>

                            <hr />
                            <label for="">新規登録</label>
                            <input v-model="dataAnswer.answer" type="text" :maxlength="25" class="form-control" />
                            <div class="text-center mt-4">
                                <button type="button" @click="register()" class="btn btn-primary">登録</button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}

.custom-class-question-tooltip::v-deep .tooltip-inner {
    max-width: 1200px;
    font-size: 14px;
}
</style>
